import React, { ChangeEvent, useState, useEffect } from 'react';
import { makeStyles, useTheme } from '@material-ui/core';
import FormField from 'common/components/formComponents/FormField';
import i18n from 'common/providers/i18n';
import CardIcon from './CardIcon';
import Txt from 'common/components/Txt';
import { BraintreeValidationError } from '../CheckoutPayment';
import Input from 'common/components/formComponents/Input';
import { validate, validation } from 'common/lib/validation';
import { HostedFields, hostedFields } from 'braintree-web';
import { Clients, useSubmitNonce } from '../BraintreeHelpers';
import { AddressInput } from 'common/interfaces/generated/globalTypes';
import { useCart } from 'common/hooks/useCart';
import { useErrorDialog } from 'common/hooks/useErrorDialog';
import { analytics, PaymentMethod } from 'common/analytics/actions';
import { getActiveCart_inStore_me_activeCart } from 'common/interfaces/generated/getActiveCart';
import { useLoading } from 'modules/Checkout/Checkout';
import getConfig from 'config';

interface Props {
  isAddressFormValid: boolean;
  clients?: Clients;
  termsAccepted: boolean;
  setSubmitCreditCardPayment: (submit: (cart: getActiveCart_inStore_me_activeCart) => void) => void;
}

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%',
    maxWidth: 450,
    '& label': {
      fontSize: theme.typography.body2.fontSize,
      lineHeight: theme.typography.body2.lineHeight,
      fontWeight: theme.typography.body2.fontWeight,
    },
    '& #cardNumber, #cvv, #expirationDate': {
      width: '100%',
      height: 50,
      margin: 0,
      backgroundColor: 'transparent',
      border: 'none',
      paddingLeft: theme.spacing(2),
      borderBottom: `1px solid ${theme.palette.grey['700']}`,
      position: 'relative',
      '& input': {
        '-webkit-box-shadow': '0 0 0px 1000px #fff inset !important;',
      },
    },
  },
  nameInput: {
    '&:focus': {
      borderBottom: `1px solid ${theme.palette.grey['700']}`,
    },
  },
  nameFormField: {
    marginBottom: theme.spacing(3),
  },
  formField: {
    position: 'relative',
    marginBottom: theme.spacing(3),
  },
  formRow: {
    display: 'flex',
    '& label': {
      width: '100%',
    },
    '& :first-child': {
      marginRight: theme.spacing(3),
    },
  },
  cardIcon: {
    position: 'absolute',
    top: 33,
    right: theme.spacing(3),
  },
  error: {
    marginTop: theme.spacing(),
    marginBottom: theme.spacing(2),
  },
}));

const CheckoutPaymentCreditcard = (props: Props): JSX.Element => {
  const { clients, isAddressFormValid, termsAccepted, setSubmitCreditCardPayment } = props;
  const theme = useTheme();
  const { cart } = useCart();
  const submitNonce = useSubmitNonce();
  const errorDialog = useErrorDialog();
  const { setLoading } = useLoading();
  const [isCardholderNameBlurred, setIsCardholderNameBlurred] = useState(false);
  const [cardType, setCardType] = useState<string | undefined>(undefined);
  const [creditCardClient, setCreditCardClient] = useState<HostedFields | undefined>(undefined);
  const [cardholderName, setCardholderName] = useState('');
  const [isCardholderNameValid, setIsCardholderNameValid] = useState(false);
  const [error, setError] = useState<BraintreeValidationError>({
    cvv: '',
    cardNumber: '',
    expirationDate: '',
  });

  const brainTreeStyles = {
    input: {
      'font-size': `${theme.typography.button.fontSize}px`,
      color: theme.palette.text.primary,
      'font-family': theme.typography.fontFamily,
      'line-height': '19px',
      'font-weight': 300,
    },
    '.submit-braintree': {
      color: theme.palette.button.primary.contrastText,
    },
    '::-webkit-input-placeholder': {
      color: theme.palette.grey['600'],
      fontWeight: 300,
    },
    ':-moz-placeholder': {
      color: theme.palette.grey['600'],
      fontWeight: 300,
    },
    '::-moz-placeholder': {
      color: theme.palette.grey['600'],
      fontWeight: 300,
    },
    ':-ms-input-placeholder': {
      color: theme.palette.grey['600'],
      fontWeight: 300,
    },
  };

  useEffect(() => {
    const init = async () => {
      const ccClient = await hostedFields.create({
        client: clients?.braintreeClient,
        styles: brainTreeStyles,
        fields: {
          number: {
            selector: '#cardNumber',
            placeholder: '0123 4567 8901 2345',
          },
          cvv: {
            selector: '#cvv',
            placeholder: '123',
          },
          expirationDate: {
            selector: '#expirationDate',
            placeholder: 'MM/YY',
          },
        },
      });
      setCreditCardClient(ccClient);
    };

    if (clients) {
      init();
    }
  }, [clients]);

  useEffect(() => {
    if (creditCardClient)
      creditCardClient?.on('cardTypeChange', (event) => {
        if (event.cards?.length !== 1) {
          setCardType(undefined);
        } else {
          setCardType(event.cards[0].type);
        }
      });
  }, [creditCardClient]);

  useEffect(() => {
    validateForm();
    // since the values of isCardHolderNameValid and isAddressFormValid are scoped to when these event handlers are set, we must update them on change
    if (creditCardClient) {
      creditCardClient.on('validityChange', validateForm);
    }
  }, [creditCardClient, isCardholderNameValid, isAddressFormValid, termsAccepted]);

  const validateForm = () => {
    const submitBtn = document.getElementById('submit-braintree');
    const cardNumberClass = document.getElementById('cardNumber')?.className;
    const expirationDateClass = document.getElementById('expirationDate')?.className;
    const cvvClass = document.getElementById('cvv')?.className;

    setError({
      cvv: cvvClass?.includes('-invalid') ? i18n.t('validation.invalid') : '​',
      cardNumber: cardNumberClass?.includes('-invalid') ? i18n.t('validation.invalid') : '',
      expirationDate: expirationDateClass?.includes('-invalid') ? i18n.t('validation.invalid') : '​',
    });

    if (
      termsAccepted &&
      isAddressFormValid &&
      isCardholderNameValid &&
      cardNumberClass?.includes('-valid') &&
      expirationDateClass?.includes('-valid') &&
      cvvClass?.includes('-valid')
    ) {
      submitBtn?.removeAttribute('disabled');
    } else {
      submitBtn?.setAttribute('disabled', 'disabled');
    }
  };

  const validateName = (name: string) => validate(name, [validation.required, validation.asciiCharacters]);

  const getBraintreeAddress = (ctAddress: AddressInput | null) => {
    if (!ctAddress) return {};
    return {
      streetAddress: ctAddress.streetName,
      extendedAddress: ctAddress.additionalStreetInfo,
      locality: ctAddress.city,
      postalCode: ctAddress.postalCode,
      countryCodeAlpha2: ctAddress.country,
    };
  };

  useEffect(() => {
    const submit = async (newCart: getActiveCart_inStore_me_activeCart) => {
      if (!creditCardClient) return;

      const billingAddress = newCart.billingAddress
        ? getBraintreeAddress(newCart.billingAddress)
        : getBraintreeAddress(newCart.shippingAddress);

      try {
        const payload = await creditCardClient.tokenize({
          cardholderName,
          billingAddress,
        });

        if (!getConfig().features.use3DS) {
          analytics.pay(cart!.id, PaymentMethod.CREDITCARD, 'fullCheckout');
          submitNonce(payload.nonce, clients, newCart, newCart.version || -1, newCart.billingAddress);
        } else {
          const secres = await clients?.threeDSecure?.verifyCard({
            amount: newCart.taxedPrice?.totalGross.centAmount / 100,
            nonce: payload?.nonce,
            email: newCart.customerEmail,
            bin: payload?.details?.bin,
            billingAddress,
            additionalInfomation: { shippingAddress: getBraintreeAddress(newCart.shippingAddress) },
            onLookupComplete: async (_: any, next: () => void) => {
              next();
            },
          });
          // because iphones are fucking stupid and zoom in on field focus we have to zoom out after 3ds
          let viewport = document.querySelector('meta[name="viewport"]');
          if (viewport === null) {
            viewport = document.createElement('meta');
            viewport.setAttribute('name', 'viewport');
            document.head.appendChild(viewport);
            viewport = document.querySelector('meta[name="viewport"]');
          }
          if (viewport) {
            viewport.setAttribute('content', 'initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0');
          }
          if (!secres.liabilityShifted) {
            setLoading(false);
            errorDialog(i18n.t('errors.tryDifferentPaymentMethod'));
          } else {
            analytics.pay(cart!.id, PaymentMethod.CREDITCARD, 'fullCheckout');
            submitNonce(
              secres?.nonce,
              clients,
              newCart,
              newCart.version || -1,
              newCart.billingAddress,
              secres.threeDSecureInfo.threeDSecureAuthenticationId
            );
          }
        }
      } catch (err) {
        errorDialog(i18n.t('errors.tryDifferentPaymentMethod'));
      }
    };

    setSubmitCreditCardPayment(submit);
  }, [creditCardClient]);

  const classes = useStyles();
  return (
    <form action="/" className={classes.form} data-testid="payment-form" id="braintree-form">
      <div className={classes.formField}>
        <FormField
          required
          label={i18n.t('braintree.NameOnCard') ?? ''}
          error={isCardholderNameBlurred ? validateName(cardholderName) : ''}
        >
          <Input
            className={classes.nameInput}
            data-testid="name-on-card"
            value={cardholderName}
            onBlur={() => setIsCardholderNameBlurred(true)}
            placeholder={i18n.t('placeholders.name') ?? ''}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setCardholderName(e.target.value);
              setIsCardholderNameValid(validateName(e.target.value) === undefined);
            }}
          />
        </FormField>
      </div>
      <div className={classes.formField}>
        {i18n.t('braintree.CardNumber')}
        <div id="cardNumber" />
        <CardIcon type={cardType} className={classes.cardIcon} />
        <Txt variant="error" className={classes.error}>
          {error.cardNumber}
        </Txt>
      </div>
      <div className={classes.formRow}>
        <label className={classes.formField}>
          {i18n.t('braintree.Expiry')}
          <div id="expirationDate" />
          <Txt variant="error" className={classes.error}>
            {error.expirationDate}
          </Txt>
        </label>
        <label className={classes.formField}>
          {i18n.t('braintree.CVV')}
          <div id="cvv" />
          <Txt variant="error" className={classes.error}>
            {error.cvv}
          </Txt>
        </label>
      </div>
    </form>
  );
};

export default CheckoutPaymentCreditcard;
